import {startWith, shareReplay} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {AvailableLang} from '@discover-swiss/models';

const DEFAULT_LANG = 'de';
const LOCAL_STORAGE_LANG_KEY = 'currentLang';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private _langSource: BehaviorSubject<AvailableLang>;
    public languageChange$: Observable<AvailableLang>;
    public availableLang = ['en', 'de', 'fr', 'it'];

    constructor() {
        const storedLang = localStorage.getItem(
            LOCAL_STORAGE_LANG_KEY
        ) as AvailableLang;
        const browserLang =
            storedLang ?? (this.getBrowserLang() as AvailableLang);
        const useLang = browserLang.match(/en|de|fr|it/) ? browserLang : DEFAULT_LANG;
        this._langSource = new BehaviorSubject<AvailableLang>(useLang);
        this.languageChange$ = this._langSource
            .asObservable()
            .pipe(startWith(useLang), shareReplay(1));
    }

    public use(lang: AvailableLang): void {
        localStorage.setItem(LOCAL_STORAGE_LANG_KEY, lang);
        this._langSource.next(lang);
    }

    get currentLang(): AvailableLang {
        return this._langSource.getValue();
    }

    public getBrowserLang(): string {
        if (
            typeof window === 'undefined' ||
            typeof window.navigator === 'undefined'
        ) {
            return undefined;
        }
        let browserLang = window.navigator.languages
            ? window.navigator.languages[0]
            : null;
        browserLang =
            browserLang ||
            window.navigator.language ||
            (window.navigator as any).browserLanguage ||
            (window.navigator as any).userLanguage;
        if (typeof browserLang === 'undefined') {
            return undefined;
        }
        if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
        }
        if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
        }
        return browserLang;
    }
}
